import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { schedulePageData } from "../../services/apiRequests.js"
//GATSBY PAGE SEO + LAYOUT
import SEO from "../../components/seo.js"
import Layout from "../../components/layout.js"
import CalendarColumn from "../../components/molecules/schedule_calendarColumn.js"
import DateContent from "../../components/molecules/schedule_dateContent.js"

// PAGE COMPONENTS AND TEMPLATES.
import { InPageNav } from "../../components/navbar.js"
import { Section } from "../../components/sections/sections.js"
import { HeroTitleH1 } from "../../components/typography.js"

const Hero = styled(Section)`
  height: 15vh;
`
const Wrapper = styled.div`
  width: 95%;
  height: 100%;
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  border-radius: 10px;
  align-items: stretch;
  margin: 0 auto;
  background-color: rgb(223, 234, 245, 0.6);
  backdrop-filter: blur(50px);
`
const sampleData = [
  {
    name: "MCAT",
    type: "online",
    package: "flex-key",
    startDate: "2020-01-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1000,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2020-01-25T00:00:00.000Z",
        endDate: "2020-02-04T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2020-02-04T00:00:00.000Z",
        endDate: "2020-02-08T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2020-02-08T00:00:00.000Z",
        endDate: "2020-02-12T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2020-02-12T00:00:00.000Z",
        endDate: "2020-02-15T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2020-02-15T00:00:00.000Z",
        endDate: "2020-02-20T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2020-02-20T00:00:00.000Z",
        endDate: "2020-02-21T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2020-02-21T00:00:00.000Z",
        endDate: "2020-02-22T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2020-02-22T00:00:00.000Z",
        endDate: "2020-02-22T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "flex-key",
    startDate: "2020-02-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1000,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2020-02-25T00:00:00.000Z",
        endDate: "2020-03-06T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2020-03-06T00:00:00.000Z",
        endDate: "2020-03-10T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2020-03-10T00:00:00.000Z",
        endDate: "2020-03-14T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2020-03-14T00:00:00.000Z",
        endDate: "2020-03-17T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2020-03-17T00:00:00.000Z",
        endDate: "2020-03-22T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2020-03-22T00:00:00.000Z",
        endDate: "2020-03-23T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2020-03-23T00:00:00.000Z",
        endDate: "2020-03-24T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2020-03-24T00:00:00.000Z",
        endDate: "2020-03-24T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "flex-key",
    startDate: "2020-03-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1000,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2020-03-25T00:00:00.000Z",
        endDate: "2020-04-04T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2020-04-04T00:00:00.000Z",
        endDate: "2020-04-08T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2020-04-08T00:00:00.000Z",
        endDate: "2020-04-12T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2020-04-12T00:00:00.000Z",
        endDate: "2020-04-15T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2020-04-15T00:00:00.000Z",
        endDate: "2020-04-20T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2020-04-20T00:00:00.000Z",
        endDate: "2020-04-21T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2020-04-21T00:00:00.000Z",
        endDate: "2020-04-22T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2020-04-22T00:00:00.000Z",
        endDate: "2020-04-22T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "flex-key",
    startDate: "2020-04-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1000,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2020-04-25T00:00:00.000Z",
        endDate: "2020-05-05T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2020-05-05T00:00:00.000Z",
        endDate: "2020-05-09T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2020-05-09T00:00:00.000Z",
        endDate: "2020-05-13T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2020-05-13T00:00:00.000Z",
        endDate: "2020-05-16T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2020-05-16T00:00:00.000Z",
        endDate: "2020-05-21T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2020-05-21T00:00:00.000Z",
        endDate: "2020-05-22T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2020-05-22T00:00:00.000Z",
        endDate: "2020-05-23T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2020-05-23T00:00:00.000Z",
        endDate: "2020-05-23T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "flex-key",
    startDate: "2020-05-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1000,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2020-05-25T00:00:00.000Z",
        endDate: "2020-06-04T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2020-06-04T00:00:00.000Z",
        endDate: "2020-06-08T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2020-06-08T00:00:00.000Z",
        endDate: "2020-06-12T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2020-06-12T00:00:00.000Z",
        endDate: "2020-06-15T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2020-06-15T00:00:00.000Z",
        endDate: "2020-06-20T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2020-06-20T00:00:00.000Z",
        endDate: "2020-06-21T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2020-06-21T00:00:00.000Z",
        endDate: "2020-06-22T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2020-06-22T00:00:00.000Z",
        endDate: "2020-06-22T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "flex-key",
    startDate: "2020-06-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1000,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2020-06-25T00:00:00.000Z",
        endDate: "2020-07-05T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2020-07-05T00:00:00.000Z",
        endDate: "2020-07-09T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2020-07-09T00:00:00.000Z",
        endDate: "2020-07-13T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2020-07-13T00:00:00.000Z",
        endDate: "2020-07-16T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2020-07-16T00:00:00.000Z",
        endDate: "2020-07-21T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2020-07-21T00:00:00.000Z",
        endDate: "2020-07-22T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2020-07-22T00:00:00.000Z",
        endDate: "2020-07-23T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2020-07-23T00:00:00.000Z",
        endDate: "2020-07-23T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "flex-key",
    startDate: "2020-07-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1000,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2020-07-25T00:00:00.000Z",
        endDate: "2020-08-04T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2020-08-04T00:00:00.000Z",
        endDate: "2020-08-08T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2020-08-08T00:00:00.000Z",
        endDate: "2020-08-12T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2020-08-12T00:00:00.000Z",
        endDate: "2020-08-15T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2020-08-15T00:00:00.000Z",
        endDate: "2020-08-20T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2020-08-20T00:00:00.000Z",
        endDate: "2020-08-21T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2020-08-21T00:00:00.000Z",
        endDate: "2020-08-22T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2020-08-22T00:00:00.000Z",
        endDate: "2020-08-22T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "flex-key",
    startDate: "2020-08-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1000,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2020-08-25T00:00:00.000Z",
        endDate: "2020-09-04T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2020-09-04T00:00:00.000Z",
        endDate: "2020-09-08T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2020-09-08T00:00:00.000Z",
        endDate: "2020-09-12T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2020-09-12T00:00:00.000Z",
        endDate: "2020-09-15T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2020-09-15T00:00:00.000Z",
        endDate: "2020-09-20T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2020-09-20T00:00:00.000Z",
        endDate: "2020-09-21T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2020-09-21T00:00:00.000Z",
        endDate: "2020-09-22T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2020-09-22T00:00:00.000Z",
        endDate: "2020-09-22T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "flex-key",
    startDate: "2020-09-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1000,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2020-09-25T00:00:00.000Z",
        endDate: "2020-10-05T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2020-10-05T00:00:00.000Z",
        endDate: "2020-10-09T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2020-10-09T00:00:00.000Z",
        endDate: "2020-10-13T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2020-10-13T00:00:00.000Z",
        endDate: "2020-10-16T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2020-10-16T00:00:00.000Z",
        endDate: "2020-10-21T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2020-10-21T00:00:00.000Z",
        endDate: "2020-10-22T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2020-10-22T00:00:00.000Z",
        endDate: "2020-10-23T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2020-10-23T00:00:00.000Z",
        endDate: "2020-10-23T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "",
    startDate: "2020-10-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1000,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2020-10-25T00:00:00.000Z",
        endDate: "2020-11-04T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2020-11-04T00:00:00.000Z",
        endDate: "2020-11-08T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2020-11-08T00:00:00.000Z",
        endDate: "2020-11-12T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2020-11-12T00:00:00.000Z",
        endDate: "2020-11-15T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2020-11-15T00:00:00.000Z",
        endDate: "2020-11-20T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2020-11-20T00:00:00.000Z",
        endDate: "2020-11-21T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2020-11-21T00:00:00.000Z",
        endDate: "2020-11-22T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2020-11-22T00:00:00.000Z",
        endDate: "2020-11-22T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "",
    startDate: "2020-11-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1000,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2020-11-25T00:00:00.000Z",
        endDate: "2020-12-05T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2020-12-05T00:00:00.000Z",
        endDate: "2020-12-09T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2020-12-09T00:00:00.000Z",
        endDate: "2020-12-13T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2020-12-13T00:00:00.000Z",
        endDate: "2020-12-16T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2020-12-16T00:00:00.000Z",
        endDate: "2020-12-21T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2020-12-21T00:00:00.000Z",
        endDate: "2020-12-22T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2020-12-22T00:00:00.000Z",
        endDate: "2020-12-23T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2020-12-23T00:00:00.000Z",
        endDate: "2020-12-23T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "",
    startDate: "2020-12-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1000,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2020-12-25T00:00:00.000Z",
        endDate: "2021-01-04T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2021-01-04T00:00:00.000Z",
        endDate: "2021-01-08T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2021-01-08T00:00:00.000Z",
        endDate: "2021-01-12T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2021-01-12T00:00:00.000Z",
        endDate: "2021-01-15T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2021-01-15T00:00:00.000Z",
        endDate: "2021-01-20T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2021-01-20T00:00:00.000Z",
        endDate: "2021-01-21T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2021-01-21T00:00:00.000Z",
        endDate: "2021-01-22T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2021-01-22T00:00:00.000Z",
        endDate: "2021-01-22T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "standard package",
    startDate: "2021-04-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1500,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2021-04-25T00:00:00.000Z",
        endDate: "2021-05-05T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2021-05-05T00:00:00.000Z",
        endDate: "2021-05-09T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2021-05-09T00:00:00.000Z",
        endDate: "2021-05-13T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2021-05-13T00:00:00.000Z",
        endDate: "2021-05-16T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2021-05-16T00:00:00.000Z",
        endDate: "2021-05-21T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2021-05-21T00:00:00.000Z",
        endDate: "2021-05-22T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2021-05-22T00:00:00.000Z",
        endDate: "2021-05-23T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2021-05-23T00:00:00.000Z",
        endDate: "2021-05-23T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "Standard",
    startDate: "2021-05-01T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1500,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2021-05-11T00:00:00.000Z",
        endDate: "2021-05-21T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2021-05-21T00:00:00.000Z",
        endDate: "2021-05-25T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2021-05-25T00:00:00.000Z",
        endDate: "2021-05-29T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2021-05-29T00:00:00.000Z",
        endDate: "2021-06-01T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2021-06-01T00:00:00.000Z",
        endDate: "2021-06-06T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2021-06-06T00:00:00.000Z",
        endDate: "2021-06-07T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2021-06-07T00:00:00.000Z",
        endDate: "2021-06-08T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2021-06-08T00:00:00.000Z",
        endDate: "2021-06-08T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "Standard",
    startDate: "2021-05-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1500,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2021-05-25T00:00:00.000Z",
        endDate: "2021-06-04T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2021-06-04T00:00:00.000Z",
        endDate: "2021-06-08T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2021-06-08T00:00:00.000Z",
        endDate: "2021-06-12T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2021-06-12T00:00:00.000Z",
        endDate: "2021-06-15T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2021-06-15T00:00:00.000Z",
        endDate: "2021-06-20T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2021-06-20T00:00:00.000Z",
        endDate: "2021-06-21T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2021-06-21T00:00:00.000Z",
        endDate: "2021-06-22T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2021-06-22T00:00:00.000Z",
        endDate: "2021-06-22T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "Standard",
    startDate: "2021-06-01T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1500,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2021-06-11T00:00:00.000Z",
        endDate: "2021-06-21T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2021-06-21T00:00:00.000Z",
        endDate: "2021-06-25T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2021-06-25T00:00:00.000Z",
        endDate: "2021-06-29T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2021-06-29T00:00:00.000Z",
        endDate: "2021-07-02T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2021-07-02T00:00:00.000Z",
        endDate: "2021-07-07T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2021-07-07T00:00:00.000Z",
        endDate: "2021-07-08T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2021-07-08T00:00:00.000Z",
        endDate: "2021-07-09T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2021-07-09T00:00:00.000Z",
        endDate: "2021-07-09T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "Standard",
    startDate: "2021-06-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1500,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2021-06-25T00:00:00.000Z",
        endDate: "2021-07-05T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2021-07-05T00:00:00.000Z",
        endDate: "2021-07-09T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2021-07-09T00:00:00.000Z",
        endDate: "2021-07-13T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2021-07-13T00:00:00.000Z",
        endDate: "2021-07-16T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2021-07-16T00:00:00.000Z",
        endDate: "2021-07-21T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2021-07-21T00:00:00.000Z",
        endDate: "2021-07-22T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2021-07-22T00:00:00.000Z",
        endDate: "2021-07-23T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2021-07-23T00:00:00.000Z",
        endDate: "2021-07-23T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "Standard",
    startDate: "2021-07-01T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1500,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2021-07-11T00:00:00.000Z",
        endDate: "2021-07-21T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2021-07-21T00:00:00.000Z",
        endDate: "2021-07-25T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2021-07-25T00:00:00.000Z",
        endDate: "2021-07-29T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2021-07-29T00:00:00.000Z",
        endDate: "2021-08-01T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2021-08-01T00:00:00.000Z",
        endDate: "2021-08-06T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2021-08-06T00:00:00.000Z",
        endDate: "2021-08-07T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2021-08-07T00:00:00.000Z",
        endDate: "2021-08-08T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2021-08-08T00:00:00.000Z",
        endDate: "2021-08-08T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "Standard",
    startDate: "2021-07-15T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1500,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2021-07-25T00:00:00.000Z",
        endDate: "2021-08-04T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2021-08-04T00:00:00.000Z",
        endDate: "2021-08-08T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2021-08-08T00:00:00.000Z",
        endDate: "2021-08-12T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2021-08-12T00:00:00.000Z",
        endDate: "2021-08-15T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2021-08-15T00:00:00.000Z",
        endDate: "2021-08-20T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2021-08-20T00:00:00.000Z",
        endDate: "2021-08-21T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2021-08-21T00:00:00.000Z",
        endDate: "2021-08-22T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2021-08-22T00:00:00.000Z",
        endDate: "2021-08-22T00:00:00.000Z",
      },
    ],
    status: "available",
  },
  {
    name: "MCAT",
    type: "online",
    package: "Standard",
    startDate: "2021-08-01T00:00:00.000Z",
    duration: 60,
    durationType: "days",
    price: 1500,
    schedule: [
      {
        subject: "CARS I",
        days: 10,
        startDate: "2021-08-11T00:00:00.000Z",
        endDate: "2021-08-21T00:00:00.000Z",
      },
      {
        subject: "CARS II",
        days: 10,
        startDate: "2021-08-21T00:00:00.000Z",
        endDate: "2021-08-25T00:00:00.000Z",
      },
      {
        subject: "Physics",
        days: 4,
        startDate: "2021-08-25T00:00:00.000Z",
        endDate: "2021-08-29T00:00:00.000Z",
      },
      {
        subject: "Chemistry",
        days: 4,
        startDate: "2021-08-29T00:00:00.000Z",
        endDate: "2021-09-01T00:00:00.000Z",
      },
      {
        subject: "Organic Chemistry",
        days: 3,
        startDate: "2021-09-01T00:00:00.000Z",
        endDate: "2021-09-06T00:00:00.000Z",
      },
      {
        subject: "Biology",
        days: 5,
        startDate: "2021-09-06T00:00:00.000Z",
        endDate: "2021-09-07T00:00:00.000Z",
      },
      {
        subject: "Pyschology",
        days: 1,
        startDate: "2021-09-07T00:00:00.000Z",
        endDate: "2021-09-08T00:00:00.000Z",
      },
      {
        subject: "Sociology",
        days: 1,
        startDate: "2021-09-08T00:00:00.000Z",
        endDate: "2021-09-08T00:00:00.000Z",
      },
    ],
    status: "available",
  },
]
// const card1 = {
//   packageName: "",
//   price: "",
//   duration: "",
//   description: "",
//   onClick: "",
//   offered: [
//     "4+ Hour Days",
//     "505+ Score Guarantee",
//     "Be Fully Prepared In 4-6 Months",
//     "90+ Hours Recorded Video Content",
//     "Master-Focussed Approach",
//     "7000+ Content Questions",
//   ],
//   notOffered: [],
// }

// const card2 = {
//   packageName: "",
//   price: "",
//   duration: "",
//   description: "",
//   onClick: "",
//   offered: [
//     "8+ Hour Days",
//     "510+ Score Guarantee",
//     "Be Fully Prepared In 3-4 Months",
//     "90+ Hours Recorded Video Content",
//     "Master-Focussed Approach",
//     "7000+ Content Questions",
//   ],
//   notOffered: [],
//   isRecommended: true,
// }
// const card3 = {
//   packageName: "",
//   price: "",
//   duration: "",
//   description: "",
//   onClick: "",
//   offered: [
//     "12+ Hour Days",
//     "515+ Score Guarantee",
//     "Be Fully Prepared In 1-2 Months",
//     "90+ Hours Recorded Video Content",
//     "Master-Focussed Approach",
//     "7000+ Content Questions",
//   ],
//   notOffered: [],
// }

//PreCARS => CARS => Physics => Chemistry => Orgo Biochem. => Bio => Psych Soc.
const McatSchedule = ({ location }) => {
  const [courseData, setData] = useState(() =>
    process.env.NODE_ENV === "production" ? [] : sampleData
  )
  const [showContent, updateShow] = useState(0)
  const RedirectToPage = dest => {
    navigate(dest)
  }
  useEffect(() => {
    if (courseData.length === 0) {
      schedulePageData("MCAT", data => {
        setData(data)
      })
    }
  }, [showContent, updateShow, courseData.length])

  return (
    <Layout navColor="transparent">
      <SEO title="99point9 MCAT Calendar" />
      <InPageNav
        currentPath={location.pathname}
        pageTitle="MCAT Schedule"
        navItems={[
          { link: "/mcat", label: "Overview" },
          { link: "/mcat/how_it_works", label: "How It Works" },
          { link: "/mcat/schedule", label: "Class Schedule" },
          { link: "/mcat/pricing", label: "Pricing" },
        ]}
        clickCallback={RedirectToPage}
      />
      <Hero wide>
        <HeroTitleH1 align="center" float="center">
          SELECT A CLASS
        </HeroTitleH1>
      </Hero>
      <Section wide>
        <Wrapper>
          {Array.isArray(courseData) && courseData.length > 0 ? (
            <>
              <CalendarColumn
                data={courseData}
                updateContentCb={index => {
                  updateShow(index)
                }}
              />
              <DateContent data={courseData[showContent]} />
            </>
          ) : (
            <div
              style={{
                width: "300px",
                textAlign: "center",
                margin: "0 auto",
                lineHeight: "1.5556",
                backgroundColor: "white",
                backgroundFilter: "none",
              }}
            >
              <span style={{}}>
                {" "}
                <strong>
                  Oh gosh, this is so embarrassing!{" "}
                  <span role="img" aria-label="Embarrassment Emoji">
                    😳
                  </span>
                </strong>
              </span>{" "}
              <br />
              <br />
              The schedule is unfortunately not available at the moment. If you
              could do us a favor, please email us at: <br /> <br />
              <span style={{ color: "#F50057" }}>
                info@99point9prep.com
              </span>{" "}
              <br /> <br /> or at{" "}
              <span style={{ color: "#F50057" }}>+1-647-899-0337</span> <br />{" "}
              <br />
              to have us sort this out for you. We're so sorry for the
              inconvenience.
            </div>
          )}
        </Wrapper>
      </Section>
    </Layout>
  )
}
export default McatSchedule
